.container-slider {
    width: 100%;
    height: 90vh;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 60px 60px;
  }

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: .7;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
  }
  .dot.active {
    background: rgb(32, 32, 32);
  }
  .title{
    text-align: center;
    font-family: Mitreba  ;
    padding-bottom: 50px;
    color: #fff;
    font-size: 80px;
    font-weight: 600;
    width: 800px;
    position: absolute;
    z-index: 10000;
    top: 30%;
    left:calc(50% - 400px);
    text-shadow: -1px 4px 6px rgba(0,0,0,0.68);
    text-shadow: 6px 4px 9px #2B2A2B;
    }
    .desc{
      font-family: Qhairo;
      font-size: 24px;
      width: 600px;
      text-align: center;
      padding: 6px;
      font-weight: 400;
      color: #fff;
      position: absolute;
      z-index: 10000;
      top: 60%;
      left:50%;
      transform: translate(-50%,-50%);
      text-shadow: 6px 4px 9px #2B2A2B;
    }
    .btn-sl{
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 20px;
      padding: 10px;
      font-weight: 700;
      color: #fff;
      background: #dda76b;
      border: none;
      width: 200px;
      position: absolute;
      z-index: 10000;
      top: 70%;
      left:calc(50% - 100px);
      border: 3px solid #dda76b;
      transition: all 200ms;
      border-radius: .25rem;
    }
    .btn-sl:hover{
      color: #f1f1f1;
      background: #dda76b;
    }

    

    @media screen and (max-width: 700px){
      .container-slider {
        margin: 0;
        height: 45vh;
      }
      .title{
        font-size: 40px;
        padding: 0;
        top: 35%;
        font-weight: bold;

      }
      .desc{
        padding: 0;
        font-size: 16px;
        width: 80vw;
        top: 57%;

      }
      .btn-sl{
        font-size: 15px;
        padding: 5px 2px;
        width: 120px;
        top: 67%;
        left:calc(50% - 60px);
      }
      .btn-slide{
        width: 30px;
        height: 30px;
      }
      .btn-slide img {
        width: 10px;
        height: 10px;
        pointer-events: none;
      }
      .prev {
        left: 5px;
        top: 60%;
        transform: translateY(-60%);
      }
      .next{
        right: 5px;
        top: 60%;
        transform: translateY(-60%);
      }
      .dot{
        width: 1px;
        height: 1px;
      }
     
    }
  