@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
 @font-face { font-family: Vilaka; src: url('./fonts/Vilaka.otf'); } 
 @font-face { font-family: Mitreba; src: url('./fonts/mitreba.otf'); } 
 @font-face { font-family: Qhairo; src: url('./fonts/qhairo.otf'); } 
 @font-face { font-family: Blastyes; src: url('./fonts/Blastyes.ttf'); } 

* {
  text-decoration: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: #2b2a2b;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #dda76b;
  border: 0px none #ffffff;
  border-radius: 27px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.navDropDownList:focus{
  color: #DDA76B;
}
.nav .active{
  border-bottom: 4px solid #DDA76B;
  color: #DDA76B;
}
.lss .active .aa{
  background-color:#DDA76B ;
  color: #dda76b;
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
}
.navlink{
  text-decoration: none;
  color: #2b2a2b;
  padding: 5px ;
  font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
}
.navlink:hover{
  color: #DDA76B;
}
.list .navlink:hover{
  color: #fff;  
  background-color: #DDA76B;
}

.list{
  display: none;
}
.active-list{
  display: flex;
}
.sublist1{
  position: relative;
}

.sublist1:hover  .list{
  display: flex;
  flex-direction: column;

}
/* .services:hover + .list{
  display: flex;
  flex-direction: column;
} */

.lg{
  display: none;
}
.active-lg{
  display: flex;
  
}
.wts{
  fill: #fff;
}
.watsContainer:hover > .wts{
  fill: #BBB;
  
}

.imgSection{
  transition: all 300ms;
}
.section:hover .imgSection{
  transform: scale(1.3) rotate(10deg);
}


.tg-active .lineToggle:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}
.tg-active .lineToggle:nth-child(2) {
  opacity: 0;
}
.tg-active .lineToggle:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg);
}
/* @media only screen and (max-width:769px){

  .hide{
    display: none;
    height: 0;

  }
  .menu{
    padding: 0;
    margin: 0;
    position: absolute;
    top:0;
    right: 0;
    height: 100vh;
    display: flex;
    transition: all 200ms;
    background: rgba(250, 250, 250, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    overflow: hidden;
    
  }
  .menu .list-mb{
    transition: all 200ms;

   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: center;
   justify-content: center;
  
  }
  .logo{
    z-index: 10000000;
    position: relative;
  }
  .navlink{
    font-size: 18px;
    background:#eee;
    padding: 10px 80px;
  }
  .nav .navlink{
    font-size: 18px;
    background:#eee;
    padding: 10px 190px;
  }
  .nav .active{
    background-color:#DDA76B ;
    color: #fff;
  }
  .list .active{
    background-color:#DDA76B ;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .list {
     margin-top: 50px
    
  }
  .sublist1:hover{
    background-color:#eee ;
    padding: 0;
    padding-top: 10px;
    margin: 0;
    width: 100%;
    border-top: 2px solid #DDA76B;
  }


} */
